<template>
  <div>
    <b-container>
      <b-row class="justify-content-md-center">
        <b-col md="6" class="content">
          
          <img src="images/logo.svg" alt="Cafe Noir Logo">

          <b-input v-model="form.mobile" size="lg" placeholder="Type your mobile number."></b-input>
          <b-input v-model="form.name" size="lg" placeholder="Type your name."></b-input>

          <b-button variant="primary" size="lg" block class="mt-4" @click="submit" :disabled="isLoading ? true: false">
            <b-spinner v-if="isLoading" style="width: 1rem; height: 1rem;"></b-spinner>
            <div v-else><i class="las la-check-circle mr-2"></i>  Submit</div>
          </b-button>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  data(){
    return {
      isLoading: false,
      form: {
        name: "",
        mobile: ""
      }
    }
  },
  methods: {
    submit: function() {
      this.isLoading = true;
      
      // CHECK IF FIELDS EMPTY
      if ( this.form.mobile == ""){
        setTimeout(() => {
          this.$toast.error("Please enter your mobile number");
          this.isLoading = false;
        }, 200);
        return;
      }

      // CHECK IF PHONE NUMBER MATCHED
      var phoneno = /^\d{10}$/;

      if ( !this.form.mobile.match(phoneno) ){
        setTimeout(() => {
          this.$toast.error("Enter a valid 10 digit mobile number");
          this.isLoading = false;
        }, 200);
        return;        
      }

      setTimeout(() => {
        this.$toast.success("Thank You.");
        this.isLoading = false;
        this.resetForm();
      }, 2000);
      return;


    },
    resetForm: function() {
      this.form.name = "";
      this.form.mobile = "";
    }
  }
}
</script>
